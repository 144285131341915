<template>
  <v-container fluid class="py-0">
    <heading>
      <h2>{{ $t("messages") }}</h2>
      <p>{{ $t("messages-.sub-heading") }}</p>
      <template #actions>
        <span></span>
      </template>
    </heading>
    <v-row>
      <v-col cols="12" md="7">
        <datatable :headers="headers" :table="entries">
          <template #[`item.body`]="{ item }">
            <span
                class="cursor-pointer"
                @click.prevent="request.phone_number = request.phone_number === '' ? item.phone_number.replace(/^\+1/, '') : request.phone_number"
            >
              {{ item.phone_number }}
            </span>
            <span
                class="cursor-pointer"
                @click.prevent="request.body = request.body === '' ? item.body : request.body"
            >
              {{ $t(item.body) }}
            </span>
          </template>
          <template #[`item.created_at`]="{ item }">
            {{ date(item.created_at) }}
          </template>
          <template #[`item.id`]="{ item }">
            <v-chip
              :color="item.sent ? 'green' : 'red'"
              text-color="white"
              class="pt-1 text-center text-lowercase"
            >
              {{ $t(item.sent ? "sent" : "failed") }}
            </v-chip>
          </template>
        </datatable>
      </v-col>
      <v-col cols="12" md="5">
        <validation-observer v-slot="{ valid }">
          <v-form @submit.prevent="submit">
            <v-row no-gutters>
              <v-col>
                <h2 class="mb-2">{{ $t(`messages-.send-sms`) }}</h2>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('phone-number').toLowerCase()"
                  :rules="{ required: true }"
                >
                  <v-text-field
                    v-model="request.phone_number"
                    outlined
                    :label="$t('phone-number')"
                    :error-messages="errors"
                  >
                    <template #[`prepend-inner`]>
                      <span style="margin-top: 3px">+1</span>
                    </template>
                  </v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('message').toLowerCase()"
                  :rules="{ required: true }"
                >
                  <v-textarea
                    v-model="request.body"
                    outlined
                    :label="$t('message')"
                    :error-messages="errors"
                  >
                  </v-textarea>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col cols="6">
                <v-btn
                  block
                  color="primary"
                  type="submit"
                  :disabled="!valid"
                  elevation="0"
                >
                  {{ $t("submit") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </validation-observer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Heading from "./components/Heading";
import Datatable from "./components/Datatable";
import messages from "../requests/messages";
import * as dayjs from "dayjs";

export default {
  mixins: [],
  components: {
    Heading,
    Datatable,
  },
  computed: {
    formAction: function () {
      return this.request.id === "" ? "new" : "edit";
    },
    headers: function () {
      return [
        {
          text: `${this.$t("phone-number")} / ${this.$t("body")}`,
          value: "body",
          sortable: false,
        },
        {
          text: this.$t("sent"),
          value: "created_at",
          sortable: false,
        },
        {
          text: "",
          value: "id",
          sortable: false,
          width: "20px",
        },
      ];
    },
  },
  data: () => ({
    entries: {
      loading: false,
      options: {},
      items: [],
      totalItems: 0,
      draw: 0,
    },
    request: {
      phone_number: "",
      body: "",
    },
  }),
  watch: {},
  created: async function () {
    this.loadEntries();
  },
  methods: {
    date: function (date) {
      return dayjs(date).format("D MMM, YYYY hh:mm a").toLowerCase();
    },
    loadEntries: async function () {
      const response = await messages.load();
      this.entries.items = response.items;
      this.entries.totalItems = response.total;
    },
    submit: async function () {
      try {
        const request = Object.assign({}, this.request);
        request.phone_number = `+1${request.phone_number}`;
        const response = await messages.create(request);
        this.resetForm();
        this.loadEntries();
        if (response.sent) {
          await this.$store.dispatch(
            "notificator/success",
            this.$t("succeeded_operation")
          );
        } else {
          await this.$store.dispatch("notificator/error", this.$t("failed_operation"));
        }
      } catch (e) {
        await this.$store.dispatch("notificator/errorResponse", e);
      }
    },
    resetForm: async function () {
      this.request = {
        phone_number: "",
        body: "",
      };
    },
    remove: async function (item) {
      if (await messages.remove(item.id)) {
        this.loadEntries();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
