import api from '@/plugins/api'

const medicalStaff = {
  load: async function () {
    try {
      const response = await api.get('/sms')
      return response.data
    } catch (e) {
      console.error(e)
    }
    return []
  },
  create: async function (request) {
    try {
      const response = await api.post('/sms', request)
      return response.data
    } catch (e) {
      console.error(e)
      throw e;
    }
  },
  remove: async function (id) {
    try {
      await api.delete(`/sms/${id}`)
      return true
    } catch (e) {
      console.error(e)
    }
    return false
  }
}

export default medicalStaff